import React from 'react';
import { useAdjustUrlMapping } from '@/src/common/hooks/utmHooks';

const FormEuroaccident = () => {
  const adjustMapping = useAdjustUrlMapping();

  // Update the document title using the browser API
  const onSubmit = (event) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();

      const brazeemail = document.getElementById('brazeemail').value;

      const params = {
        email: brazeemail,
        source: 'email_landing_page',
      };
      window.analytics.identify({ ...params, ...adjustMapping });

      document.getElementById('emailSubmit').value = 'Skickar...';

      setTimeout(() => {
        document.getElementById('emailSubmit').value = 'Skickat';
        window.analytics.track('Onboarding Viewed');
      }, 1500);
    }
  };

  return (
    <form id="brazeForm" onSubmit={onSubmit} className="form">
      <div>
        <div className="grid grid--gap-sm margin-top--xs">
          <div className="col--md-6">
            <input
              id="brazeemail"
              type="email"
              autoCapitalize="off"
              autoCorrect="off"
              defaultValue=""
              placeholder="Ange din e-postadress"
              className="form-control form-control--md form-control--white"
              required
              style={{ border: 'solid 1px #e1e8ef' }}
            />
          </div>
          <div className="col--md-6">
            <input
              id="emailSubmit"
              type="submit"
              value="Ladda ner"
              name="submit"
              className="btn btn--primary btn--md btn--square btn--fullwidth"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormEuroaccident;
