import React from 'react';
import Segment from '../../components/segment';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import HeroAnimation from '../../components/sections/heroAnimation/heroAnimation';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';

const IndexPage = () => {
  const lang = 'se_sv';
  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      dataTheme="white"
      HeaderFloatShow
      headerWitdh="flex--start"
      headerStyle="container container--lg"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />
      <Seo title="Behandla artros och ledsmärta direkt i mobilen" language="sv" />
      <HeroAnimation
        dataTheme="white"
        title="Behandla artros och ledsmärta direkt i mobilen"
        titleClassName="bold"
        text="Ladda ner Joint Academy till din mobil idag."
        tagHeadline="Få ett sms med länk för att ladda ner appen"
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="kom igång idag"
        ctaDataGoal="CTA Clicked"
        ctaHref="#herotext"
      />
      <SocialProofFeedST lang={lang} sectionClassName="margin-bottom--lg" />
    </Layout>
  );
};

export default IndexPage;
