import React from "react"
// import "./heroAnimation.scss"
import SvgImage from "../../../assets/hero-img@2x.png"
import BrazeForms from "../../../components/atoms/brazeForms"
import SocialMinimal from "../../atoms/socialMinimal"

const HeroAnimation = ({
  dataTheme,
  title,
  titleClassName,
  text,
  textClassName,
}) => {
  return (
    <section
      className="heroAnimation"
      data-theme={dataTheme}
      id="top"
      style={{ overflow: "visible" }}
    >
      <div className="container container--lg">
        <div className="grid flex--start">
          <div
            id="herotext"
            className="col col--12 col--sm-6 margin-top--sm alignItems"
          >
            <h1
              className={`margin-bottom--md margin-top--lg ${titleClassName}`}
            >
              {title}
            </h1>
            <p className={textClassName}>{text}</p>
            <div className="margin-top--xs">
              <BrazeForms />
            </div>
            <div className="margin-top--md maxWidthSocial">
              <SocialMinimal />
            </div>
          </div>

          <div className="col col--12 col--sm-6 fullwidth--sm SvgImage">
            <img className="" alt="SvgImage" src={SvgImage} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroAnimation
